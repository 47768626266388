<template>
    <div>
      <b-row>
        <b-col lg="6" md="6" sm="6" class="heading-title">
          <h4>Drafts/Quoation Booking</h4>
          <b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
            <b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
            <b-breadcrumb-item active>Drafts/Quoation Booking</b-breadcrumb-item>
          </b-breadcrumb>
        </b-col>
        <b-col lg="6" md="6" sm="6" class="btns-header"> </b-col>
      </b-row>
      <br />
      <b-card class="mb-4 w-100">
        <div class="d-flex justify-content-between align-items-center row py-3 gap-3 gap-md-0">
      <div class="col-md-3 product_status">
        <multiselect
                :options="venue_names"
                placeholder="Select Venue Name"
                v-model="venue_name_value"
              >
              </multiselect>
      </div>
      <div class="col-md-3 product_category">
        <b-form-datepicker
                id="example-datepicker"
                v-model="start_date"
                placeholder="Start date"
                class="mb-2"
                :max="end_date"
                :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                }"
              ></b-form-datepicker>
      </div>
      <div class="col-md-3 product_stock">
        <b-form-datepicker
                id="example-datepicker2"
                placeholder="End date"
                :min="start_date"
                v-model="end_date"
                class="mb-2"
                :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                }"
              ></b-form-datepicker>
      </div>
      <div class="col-md-3 product_stock">
        <div class=" d-flex justify-content-center button-text">
              <div class="p-1">
                <b-button variant="primary" block @click="applyFilter()"  >
                  Apply</b-button
                >
              </div>
              <div class="p-1">
                <b-button variant="primary"  block @click="resetFilter()">
                  Reset</b-button
                >
              </div>
              </div>
      </div>
    </div>
    <hr>
    <div class="d-flex justify-content-between align-items-center row py-3 gap-3 gap-md-0">
      <div class="col-md-3 product_status">
        <b-form-input
                v-model="search"
                id="search"
                type="text"
                placeholder="Search here"
                v-on:keyup="key_search()"
        ></b-form-input>
      </div>
      <div class="col-md-4 product_status">
       
        <div class=" d-flex justify-content-end button-text">
          <div class=" venue-flex">
                <b-form-select
                  style="max-width: 90px;"
                  id="select_perpage"
                  v-model="pageSize"
                  :options="options"
                  @change="handlePageSizeChange($event)"
                ></b-form-select>
        </div>
         
             
              <div class="p-1">
                <b-button
                  id="button_width"
                  @click="export_pdf()"
                  variant="primary"
                >
                  <i class="mdi mdi-export"></i>Export</b-button
                >
              </div>
        </div>
     </div>
    </div>




       <!--  <div class="venue-heading">
				<b-row>
					<b-col
					class="d-flex align-items-stretch"
					cols="12"
					lg="3"
					md="5"
					sm="12"
					>
						<div class="w-100">
							<multiselect
							:options="venue_names"
							placeholder="Select Venue Name"
							v-model="venue_name_value"
							>
							</multiselect>
						</div>
					</b-col>
					<b-col
					class="d-flex align-items-stretch"
					cols="12"
					lg="2"
					md="4"
					sm="12"
					>
						<div class="w-100">
							<b-form-datepicker
							id="example-datepicker"
							v-model="start_date"
							placeholder="Start date"
							class="mb-2"
							:max="end_date"
							:date-format-options="{
							year: 'numeric',
							month: 'short',
							day: '2-digit',
							}"
							></b-form-datepicker>
						</div>
					</b-col>
					<b-col
					class="d-flex align-items-stretch"
					cols="12"
					lg="2"
					md="4"
					sm="12"
					>
						<div class="w-100">
							<b-form-datepicker
							id="example-datepicker2"
							placeholder="End date"
							:min="start_date"
							v-model="end_date"
							class="mb-2"
							:date-format-options="{
							year: 'numeric',
							month: 'short',
							day: '2-digit',
							}"
							></b-form-datepicker>
						</div>
					</b-col> 
					
					<b-col
					class="d-flex align-items-stretch"
					cols="12"
					lg="4"
					md="12"
					sm="12"
					>
						<div class=" d-flex justify-content-end button-text">
							<div class="p-1">
								<b-button variant="primary" @click="applyFilter()">
									Apply</b-button
								>
							</div>
							<div class="p-1">
								<b-button variant="primary" @click="resetFilter()">
									Reset</b-button
								>
							</div>
							<div class="p-1">
							
							</div>
							
							<div class="p-1">
								<b-button
								id="button_width"
								@click="export_pdf()"
								variant="primary"
								>
									<i class="mdi mdi-export"></i>Export</b-button
								>
							</div>
						</div>
					</b-col>
				</b-row>
			</div> -->
			
			<!-- <div>
				<b-row>
					<b-col
					class="d-flex align-items-stretch"
					cols="12"
					lg="7"
					md="12"
					sm="12"
					>
						<div
						class="
						w-100
						justify-content-sm-center
						justify-content-xl-start
						justify-content-md-start
						"
						>
							<div class="mt-2 venue-flex">
								<b-form-select
								style="max-width: 90px;"
								id="select_perpage"
								v-model="pageSize"
								:options="options"
								@change="handlePageSizeChange($event)"
								></b-form-select> -->
							<!-- 	<div class="venue-heading">
									<b-form-datepicker
									id="example-datepicker2"
									placeholder="Booked On"
									v-model="booked_on"
									class="mb-2"
									:date-format-options="{
									year: 'numeric',
									month: 'short',
									day: '2-digit',
									}"
									></b-form-datepicker>
								</div> -->
						<!-- 	</div>
						</div>
					</b-col>
					
					<b-col
					class="d-flex align-items-stretch"
					cols="12"
					lg="5"
					md="12"
					sm="12"
					>
						<div class="w-100 p-1">
							<b-form-input
							v-model="search"
							id="search"
							type="text"
							placeholder="Search here"
							v-on:keyup="key_search()"
							></b-form-input>
						</div>
					</b-col>
				</b-row>
			</div> -->
        <div>
          <b-table
            :busy="load"
            :fields="fields"
            :items="bookings"
            responsive
            hover
            bordered
            show-empty
            empty-text="No Data Available"
            class="text-center"
          >
          <template  #cell(total_booking_price)="data">
             <span v-html="currency_check(data.item.total_booking_price)"></span>
          </template>
           <!--  <template #cell(booking_id)="data">
            
 
              <router-link :to="'/Draft_Quotation/draft_details/' + data.item.booking_id">
              <i class="fas fa-eye fa-lg text-dark"></i>
            </router-link>
            <router-link :to="'/Draft_Quotation/view_details/' + data.item.booking_id"  v-if="data.item.type=='Quotation'">
              <i class="fas fa-file fa-lg text-dark ml-2"></i>
            </router-link>
           
              <i class="fas fa-file fa-lg text-dark ml-2 " disabled  v-else></i>
          

            </template> -->
            <template  #cell(booking_type)="data">
             <span v-if="data.item.booking_type=='Quotation'" class="badge badge-info">
              Quotation
            </span>
            <span v-else class="badge badge-warning">
              Draft
            </span>
          </template>
            <template #cell(booking_id)="data">
              <!-- <router-link :to="'/my-booking/view_details/' + data.item.booking_id">
                <i class="fas fa-eye fa-lg text-dark"></i>
              </router-link>
 -->
 <span v-if="data.item.status!=1">
 <span v-if="data.item.Validity==0">
<i class="text-danger">Expired</i>
</span>
<span v-else-if="data.item.Validity==1">
  <router-link :to="'/Draft_Quotation/draft_details/' + data.item.booking_id">
              <i class="fas fa-eye fa-lg text-dark"></i>
            </router-link>
            <router-link :to="'/Draft_Quotation/view_details/' + data.item.booking_id"  v-if="data.item.type=='Quotation'">
              <i class="fas fa-file fa-lg text-dark ml-2"></i>
            </router-link>
</span>
<span v-else>
  <router-link :to="'/Draft_Quotation/draft_details/' + data.item.booking_id">
              <i class="fas fa-eye fa-lg text-dark"></i>
            </router-link>
  <i class="fas fa-file fa-lg text-dark ml-2 " disabled  ></i>
</span>
 </span>
 <span v-else>
  <i class="text-success">Booked</i>
 </span>
              
           
            
          

            </template>
            
          </b-table>
          <b-row align-v="center">
            <b-col cols="4">
              <span
                >Showing <b>{{ totalRows }}</b> out of
                <b>{{ totalData }}</b> entries.</span
              >
            </b-col>
            <b-col cols="8">
              <b-pagination
                v-model="page"
                :total-rows="count"
                :per-page="pageSize"
                @change="handlePageChange"
                prev-text="Prev"
                next-text="Next"
                align="right"
              ></b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </template>
  <script>
  import axios from "axios";
  import helpers from "../../../mixins/helpers";
  import Multiselect from "vue-multiselect";
  export default {
    name: "offlinesavedraft",
    mixins: [helpers],
    data: () => ({
      title: "offlinesavedraft",
  
      fields: [
        //  'index',
        {
        key: "auto_increment",
        label: "Booking ID",
        sortable: true,
        },
        {
          key: "place_name",
          label: "Venue Name",
          sortable: true,
        },
        {
          key: "customer_name",
          label: "Customer Name",
          sortable: true,
        },
        {
          key: "booked_on",
          label: "Booked On",
          sortable: true,
        },
       {
          key: "booking_type",
          label: "Booking Type",
          sortable: true,
        }, 
       /*   {
          key: "billing_phone",
          label: "Billing Phone",
        },
        {
          key: "billing_email",
          label: "Billing Email",
          sortable: true,
        },*/
        {
          key: "total_booking_price",
          label: "Amount",
          sortable: true,
          tdClass: 'text-right',
        },
       /*  {
          key: "from_date",
          label: "From Date",
          sortable: true,
        },
        {
          key: "to_date",
          label: "To Date",
          sortable: true,
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
        }, */
        {
           key: "booking_id",
           label: "Action",
         },
      ],
      bookings: [],
      options: [10, 20, 30, 50],
  
      pageSize: 10,
      show: false,
      load: true,
      filter: "",
      search: "",
      CurrentPage: 1,
      sortBy: "created_at",
  
      page: 1,
      count: 0,
      totalData: "",
      totalRows: "",
  
      PerPage: "50",
      venue_names: [],
      start_date: "",
      end_date: "",
      booked_on: "",
      venue_name_value: "",
      curncy: "",
    }),
    components: { Multiselect },
    methods: {
      key_search() {
        const value = "1";
        this.handlePageChange(value);
      },
      handlePageChange(value) {
        this.page = value;
        this.load = true;
        this.bookingListing();
      },
      applyFilter() {
        const value = "1";
        this.handlePageChange(value);
      },
      getRequestParams(page, pageSize, sortBy = "created_at") {
        let params = {};
        if (page) {
          params["page"] = page - 1;
        }
        if (pageSize) {
          params["size"] = pageSize;
        }
        params["sortBy"] = sortBy;
        return params;
      },
      bookingListing() {
        this.$store.commit("SET_BOOKING_HEADER", 'Draft Booking');
        this.load = true;
        // this.venue = [];
        var keyword = this.search;
        const params = this.getRequestParams(
          this.page,
          this.pageSize,
          this.sortBy
        );
        axios
          .post("/admin/get_offline_save_drafts", {
            params,
            search_keyword: keyword,
            from_date: this.start_date,
            to_date: this.end_date,
            booked_on: this.booked_on,
            venue_name: this.venue_name_value,
          })
          .then((resp) => {
            if (resp.data.status_code == 200) {
              this.bookings = [];
              if (resp.data.status) {
                this.count = this.totalData = resp.data.total_records;
                this.totalRows = resp.data.booking_save_draft.length;
  
                for (var i = 0; i < resp.data.booking_save_draft.length; i++) {
                  var shift;
                if(resp.data.booking_save_draft[i].booked_shift_type==1)
                {
                  shift="Morning";
                }
                else if(resp.data.booking_save_draft[i].booked_shift_type==2)
                {
                  shift="Afternoon";
                }
                else if(resp.data.booking_save_draft[i].booked_shift_type==3)
                {
                  shift="Evening";
                }

                  this.bookings.push({
                    booking_id: resp.data.booking_save_draft[i].booking_save_draft_id,
                  /*   from_date: resp.data.booking_save_draft[i].from_date,
                    to_date: resp.data.booking_save_draft[i].to_date,
                    billing_phone: resp.data.booking_save_draft[i].billing_phone,
                    billing_email: resp.data.booking_save_draft[i].billing_email, */
                    total_booking_price:
                      this.formatPrice(resp.data.booking_save_draft[i].total_booking_amount),
                    booked_on: this.date_format(resp.data.booking_save_draft[i].from_date)+' - '+shift,
                    booking_type: this.makeUpperCase(resp.data.booking_save_draft[i].type),
                    place_name: resp.data.booking_save_draft[i].child_venue_name ? resp.data.booking_save_draft[i].child_venue_name.substring(0,15) : '',
                  /*   vendor_id: resp.data.booking_save_draft[i].vendor_id,
                    vendor_name: resp.data.booking_save_draft[i].vendor_name, */
                    customer_name: resp.data.booking_save_draft[i].billing_first_name,
                   // auto_increment: resp.data.booking_save_draft[i].auto_increment,
                   // type: this.makeUpperCase(resp.data.booking_save_draft[i].type),
                    auto_increment: resp.data.booking_save_draft[i].auto_increment,
                    Validity: resp.data.booking_save_draft[i].Validity,
                    status: resp.data.booking_save_draft[i].status,
                    type: this.makeUpperCase(resp.data.booking_save_draft[i].type),
                  });
                }
                this.show = false;
                this.load = false;
              } else {
                this.count = this.totalData = resp.data.total_records;
                this.totalRows = resp.data.booking_save_draft.length;
  
                this.show = false;
                this.load = false;
              }
            }
          });
      },
      formatPrice(value) {
        //let val = (value/1).toFixed(2).replace(',', '.')
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    
      handlePageSizeChange(event) {
        this.pageSize = event;
        this.load = true;
        this.page = 1;
        this.bookingListing();
      },
      AddOffline() {
        this.$router.push("/add-booking");
      },
      resetFilter() {
        this.start_date = "";
        this.end_date = "";
        this.search = "";
        this.booked_on = "";
        (this.venue_name_value = ""), this.bookingListing();
      },
      export_pdf() {
        var keyword = this.search;
        const params = this.getRequestParams(
          this.page,
          this.pageSize,
          this.sortBy
        );
        axios
          .post(
            "/admin/bookings_pdf_export",
            {
              params,
              search_keyword: keyword,
              from_date: this.start_date,
              to_date: this.end_date,
              booked_on: this.booked_on,
              venue_name: this.venue_name_value,
            },
            {
              responseType: "blob",
            }
          )
          .then((resp) => {
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "booking_list.pdf");
            document.body.appendChild(link);
            link.click();
          });
      },
      getVenueName() {
        axios.post("/admin/get_child_venue_name").then((resp) => {
          if (resp.data.status_code == 200) {
            if (resp.data.status) {
              this.venue_names = resp.data.venue_names;
            }
          }
        });
      },
      currency_check(amt) {
       
        
       return '<i class="mdi '+this.curncy+'"></i>'+amt;
   },
   getProfile() {
     axios.get("/admin/get_profile").then((resp) => {
           if (resp.data.status_code == 200) {
               this.curncy= resp.data.user_details.currencys;
           }
       });
   },
    },
    mounted() {
      this.bookingListing();
      // this.getVenueName();
       this.getProfile();
       document.documentElement.scrollTop = 0;
    },
  };
  </script>
  <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
  <style scoped>
  #search {
    max-width: 280px;
  }
  </style>